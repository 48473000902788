<template>
  <div class="w-full rounded z-0 relative">
    <tabs
      ref="activityStreamTabs"
      :options="{
        disableScrollBehavior: true,
        defaultTabHash: 'activity-stream-tab',
      }"
    >
      <tab
        id="activity-stream-tab"
        class="mr-2 mb-5 p-2 overflow-visible scrollbar-hide bg-white drop-shadow-md rounded text-rev-xl"
        name="Activity Stream"
      >
        <AcivityTab :work-item-id="workItemId" />
      </tab>
      <tab
        class="mr-2 mb-5 p-2 overflow-visible scrollbar-hide bg-white drop-shadow-md rounded text-rev-xl"
        name="Charges"
      >
        <ChargesTab :work-item-id="workItemId" />
      </tab>
      <tab
        class="mr-2 mb-5 p-2 overflow-visible scrollbar-hide bg-white drop-shadow-md rounded text-rev-xl"
        name="Transactions"
      >
        <TransactionsTab :work-item-id="workItemId" />
      </tab>
      <tab v-if="hasViewDocumentsPermissions === true" name="Documents">
        <DocumentsTab :work-item-id="workItemId" />
      </tab>
    </tabs>
  </div>
</template>
<script setup>
import { useUsersStore } from '@/stores/useUsers';
import { ref, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import AcivityTab from './tabs/ActivityTab.vue';
import ChargesTab from './tabs/ChargesTab.vue';
import TransactionsTab from './tabs/TransactionsTab.vue';
import DocumentsTab from './tabs/DocumentsTab.vue';

const users_store = useUsersStore();

const hasViewDocumentsPermissions = ref(false);
onMounted(async () => {
  hasViewDocumentsPermissions.value =
    (await users_store.hasPermission('VIEW_DOCUMENTS')) === true;
});
const route = useRoute();
const activityStreamTabs = ref(null);

const workItemId = ref(route.params.id);

watch(
  route,
  () => {
    if (activityStreamTabs.value)
      activityStreamTabs.value.selectTab('#activity-stream-tab');
  },
  { deep: true, immediate: true }
);
</script>
<style scoped>
.cursor-pointer:hover {
  box-shadow: -5px 0px 0px 0px lightgrey, 5px 0px 0px 0px lightgrey;
}

tr td {
  width: 25%;
}
</style>

import { defineStore } from 'pinia';
import { API } from 'aws-amplify';
import getDynamicPath from '../utils/getDynamicPath';
import { toast } from 'vue3-toastify';
import { useUsersStore } from '@/stores/useUsers';

import { getAuthenticatedHeaders } from '@/utils/auth';

export const useWorkItemsStore = defineStore('workItems', {
  state: () => ({
    workItems: [],
    notFound: false,
    workItem: null,
    workItemChargesTotal: 0,
    workItemPaymentsTotal: 0,
    workItemAdjustmentsTotal: 0,
    workItemActivities: [],
    workItemCharges: [],
    workItemTransactions: [],
    isLoading: true,
    isClaimStatusLoading: false,
    activityLookup: [],
    queryResults: [],
    activityCursor: 0,
    activityRecords: 0,
    activityLimit: 25,
    activityPage: 1,
    activityDirection: 'DESC',
    activityField: 'mod_timest',
    chargesCursor: 0,
    chargesRecords: 0,
    chargesLimit: 25,
    chargesPage: 1,
    chargesDirection: 'ASC',
    chargesField: 'mod_timest',
    transactionsCursor: 0,
    transactionsRecords: 0,
    transactionsLimit: 25,
    transactionsPage: 1,
    transactionsDirection: 'ASC',
    transactionsField: 'mod_timest',
  }),
  getters: {
    getWorkItemValue: state => {
      return field => {
        return state.workItem?.[field];
      };
    },
    getActivityStreamRecords: state => {
      return () => {
        return state.workItemActivities;
      };
    },
  },
  actions: {
    async fetchWorkItem(id) {
      const headers = await getAuthenticatedHeaders();
      this.isLoading = true;
      try {
        this.notFound = false;
        const response = await API.get(
          'insurance',
          getDynamicPath(`workItems/${id}`),
          headers
        );

        const { data: responseData } = response;

        this.workItem = responseData?.data ?? {};
        this.workItemPaymentsTotal = responseData.data.payments_summary;
        this.workItemAdjustmentsTotal = responseData.data.adjustments_summary;
        this.workItemChargesTotal = responseData.data.charges_summary;
      } catch (err) {
        console.error(err.response);

        if (err.response?.status == 404) {
          this.notFound = true;
        }
      } finally {
        this.isLoading = false;
      }
    },
    async setWorkItemStatus(workItemId, status, fromSearch = false) {
      const headers = await getAuthenticatedHeaders();
      const payload = {
        ...headers,
        body: {
          fromSearch,
        },
      };
      try {
        const response = await API.put(
          'insurance',
          getDynamicPath(`assignments/${workItemId}?action=${status}`),
          payload
        );

        const { data: responseData } = response;

        if (!responseData.success) {
          toast.error(responseData.message);
          return responseData;
        } else {
          return responseData;
        }
      } catch (err) {
        toast.error(err.response?.data?.message ?? err.message);
        console.error(err.response ?? err);
        return err.response ?? err;
      }
    },
    async fetchActivitiesLookup(id) {
      const headers = await getAuthenticatedHeaders();
      try {
        const { data: response } = await API.get(
          'insurance',
          getDynamicPath(`lookups/activities/${id}`),
          headers
        );
        this.activityLookup =
          response?.data.map(a => ({
            ...a,
            value: a.name,
            key: parseInt(a.activity_subtype_id),
          })) || [];
      } catch (err) {
        console.error(err);
      }
    },

    async fetchWorkItemActivities(
      id,
      page = 1,
      direction = 'desc',
      field = 'mod_timest'
    ) {
      const headers = await getAuthenticatedHeaders();
      this.isLoading = true;
      this.activityPage = page;
      this.activityDirection = direction;
      this.activityField = field;

      const limit = localStorage.getItem('activity_pagination')
        ? parseInt(localStorage.getItem('activity_pagination'), 10)
        : parseInt(this.activityLimit);

      let adjustedHeaders = {
        ...headers,
        queryStringParameters: {
          cursor: page ? (page - 1) * limit : this.activityCursor,
          limit: limit,
          field: field,
          direction: direction,
          type: 'activities',
        },
      };
      try {
        const response = await API.get(
          'insurance',
          getDynamicPath(`workItems/${id}/activities`),
          adjustedHeaders
        );
        this.workItemActivities = response?.data?.data ?? [];
        this.activityRecords = response?.data?.data.length || 0;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchWorkItemCharges(
      id,
      page = 1,
      direction = 'asc',
      field = 'mod_timest'
    ) {
      const headers = await getAuthenticatedHeaders();
      this.isLoading = true;
      this.chargesPage = page;
      this.chargesDirection = direction;
      this.chargesField = field;

      const limit = localStorage.getItem('charges_pagination')
        ? parseInt(localStorage.getItem('charges_pagination'), 10)
        : parseInt(this.chargesLimit);

      let adjustedHeaders = {
        ...headers,
        queryStringParameters: {
          cursor: page ? (page - 1) * limit : this.chargesCursor,
          limit: limit,
          field: field,
          direction: direction,
          type: 'charges',
        },
      };
      try {
        const response = await API.get(
          'insurance',
          getDynamicPath(`workItems/${id}/activities`),
          adjustedHeaders
        );
        this.workItemCharges = response?.data?.data ?? [];
        this.chargesRecords = response?.data?.data?.length || 0;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchWorkItemTransactions(
      id,
      page = 1,
      direction = 'asc',
      field = 'mod_timest'
    ) {
      const headers = await getAuthenticatedHeaders();
      this.isLoading = true;
      this.transactionsPage = page;
      this.transactionDirection = direction;
      this.transactionField = field;

      const limit = localStorage.getItem('transactions_pagination')
        ? parseInt(localStorage.getItem('transactions_pagination'), 10)
        : parseInt(this.transactionLimit);

      let adjustedHeaders = {
        ...headers,
        queryStringParameters: {
          cursor: page ? (page - 1) * limit : this.transactionsCursor,
          limit: limit,
          field: field,
          direction: direction,
          type: 'tx',
        },
      };
      try {
        const response = await API.get(
          'insurance',
          getDynamicPath(`workItems/${id}/activities`),
          adjustedHeaders
        );
        this.workItemTransactions = response?.data?.data ?? [];
        this.transactionsRecords = response?.data?.data?.length || 0;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
    async getClaimStatus() {
      const users_store = useUsersStore();
      const auxo_user_id = users_store.activeUser?.auxo_user_id;
      const headers = await getAuthenticatedHeaders();
      try {
        const payload = {
          body: {
            tin: this.workItem.tax_id,
            plan_id: this.workItem.current_plan_id,
            lookup_value: this.workItem.claim_number,
            reply_to: auxo_user_id,
          },
          ...headers,
        };

        const { data: response } = await API.post(
          'insurance',
          getDynamicPath(`workItems/${this.workItem.work_item_id}/claimStatus`),
          payload
        );
        return response;
      } catch (err) {
        toast.error(
          err.response?.data?.message ??
            'Unable to retrieve claim status. Please check directly with the payer.'
        );
        console.error(err.response ?? err);
        return err.response ?? err;
      }
    },
    async postActivity(
      workItemId,
      activityId,
      activityName,
      subactivityId,
      subActivityName,
      notes,
      ua_id,
      fromSearch
    ) {
      const headers = await getAuthenticatedHeaders();
      const users_store = useUsersStore();
      const auxo_user_id = users_store.activeUser?.auxo_user_id;
      const payload = {
        body: {
          activity_type_id: activityId,
          activity_type: activityName,
          activity_subtype_id: parseInt(subactivityId, 10),
          activity_subtype: subActivityName,
          note: notes,
          ua_id,
          auxo_user_id,
          fromSearch,
        },
        ...headers,
      };

      try {
        const response = await API.post(
          'insurance',
          getDynamicPath(`workItems/${workItemId}/activities`),
          payload
        );
        return response;
      } catch (err) {
        console.error(err);
        return null;
      }
    },
    reset() {
      this.workItem = null;
    },
  },
});

<template>
  <button
    v-if="!showBot"
    class="absolute right-4 bottom-[12%] z-10 p-3 py-3 rounded-full bg-primary border-4 border-green-300 expandable-button animate-pulse-colors"
    @click="openChatBot"
  >
    <div class="flex gap-2">
      <img :src="AuxoIcon" class="w-6 h-6" />
    </div>
  </button>
  <div
    v-if="showBot"
    class="flex flex-col border p-4 bg-white mr-[-1.75rem] my-[-1.75rem] min-w-[300px]"
  >
    <div class="flex justify-between items-center">
      <div class="flex items-center gap-3">
        <div
          class="p-2 rounded-full bg-primary flex items-center justify-center"
        >
          <img :src="AuxoIcon" class="w-4 h-4" />
        </div>
        <div class="flex flex-col">
          <h1>Auxo AI</h1>
          <DescriptionPopover :hoverText="'Disclaimer'" />
        </div>
      </div>
      <BaseButton @click="closeChatBot">
        <img :src="CloseIcon2" />
      </BaseButton>
    </div>
    <hr class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700" />
    <p class="text-gray-300 mb-3">
      How can I help? Ask me a question about your claim.
    </p>
    <div class="grow"></div>
    <div
      id="scrollableDiv"
      class="flex flex-col items-center gap-3 overflow-y-auto overflow-x-hidden pt-3 will-change-auto"
    >
      <div
        v-show="messageList.length < 1"
        v-for="button in props.buttonList"
        :key="button"
        class="w-full flex justify-center"
      >
        <button
          class="bg-gray-200 hover:bg-blue-100 w-5/6 rounded-lg text-left p-2"
          @click="askQuestion(button)"
        >
          {{ button }}
        </button>
      </div>

      <div
        v-for="message in messageList"
        :key="message.id"
        class="flex flex-col gap-4 w-full"
      >
        <ChatBubble
          :text="message.text"
          :user="message.user"
          :timestamp="message.timestamp"
        />
      </div>

      <hr class="h-px my-3 bg-gray-200 border-0 dark:bg-gray-700 w-full" />
      <form
        class="flex gap-3 w-full items-center"
        @submit.prevent="askFreeformQuestion"
      >
        <div class="flex w-full flex-col">
          <input
            v-model="prompt"
            required="true"
            type="text"
            placeholder="Ask Auxo about your claim"
            :class="['border p-2 w-full', { 'border-red-500 ': errorMessage }]"
          />
          <p v-if="errorMessage" class="text-red-500 text-rev-sm">
            {{ errorMessage }}
          </p>
        </div>

        <SolidButton
          type="submit"
          :disabled="!!errorMessage"
          class="bg-secondary border-secondary p-2"
        >
          Send
        </SolidButton>
      </form>
    </div>
  </div>
</template>

<style scoped>
@keyframes pulseColors {
  0% {
    box-shadow: 0 0 0 0px rgba(132, 225, 188, 0.5);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
  }
}

.animate-pulse-colors {
  animation: pulseColors 2s infinite;
}
</style>

<script setup>
import { ref, watch, nextTick } from 'vue';
import { useUsersStore } from '@/stores/useUsers';
import { useChatbotStore } from '@/stores/useChatbot';

import SolidButton from './buttons/SolidButton.vue';
import BaseButton from './buttons/BaseButton.vue';
import ChatBubble from './chat-ai/ChatBubble.vue';
import CloseIcon2 from '@/assets/close-icon-2.svg';
import AuxoIcon from '@/assets/auxo-icon.svg';
import DescriptionPopover from '@/components/DescriptionPopover.vue';

const props = defineProps({
  buttonList: {
    type: Array,
    default: () => [],
  },
  page: {
    type: String,
    default: '',
  },
  itemId: {
    type: String,
    default: '',
  },
});

const usersStore = useUsersStore();
const chatbotStore = useChatbotStore();

let showBot = ref(false);
let errorMessage = ref(false);
let prompt = ref('');
let messageList = ref([]);

watch(prompt, () => {
  checkCharacterCount();
});

const openChatBot = () => {
  showBot.value = true;
  nextTick(() => {
    const messageListContainer = document.getElementById('scrollableDiv');
    if (messageListContainer) {
      messageListContainer.scrollTop = messageListContainer.scrollHeight;
    }
  });
};
const closeChatBot = () => {
  showBot.value = false;
};

const askQuestion = async question => {
  messageList.value = [
    ...messageList.value,
    {
      text: {
        body: question,
      },
      timestamp: new Date(),
      user: usersStore.activeUser,
    },
  ];
  messageList.value = [
    ...messageList.value,
    {
      timestamp: new Date(),
    },
  ];
  nextTick(() => {
    const messageListContainer = document.getElementById('scrollableDiv');
    if (messageListContainer) {
      messageListContainer.scrollTop = messageListContainer.scrollHeight;
    }
  });
  try {
    //this is for the prompt engineering
    const response = await chatbotStore.generateAndFormatResponse(
      question,
      props
    );
    messageList.value[messageList.value.length - 1] = {
      timestamp: new Date(),
      text: response,
    };
  } catch (error) {
    messageList.value[messageList.value.length - 1] = {
      timestamp: new Date(),
      text: { body: `There was an error retreiving your response: ${error}` },
    };
  }
  nextTick(() => {
    const messageListContainer = document.getElementById('scrollableDiv');
    if (messageListContainer) {
      messageListContainer.scrollTop = messageListContainer.scrollHeight;
    }
  });
};

const askFreeformQuestion = async () => {
  checkCharacterCount();
  messageList.value = [
    ...messageList.value,
    {
      text: {
        body: prompt.value,
      },
      timestamp: new Date(),
      user: usersStore.activeUser,
    },
  ];
  const temp = prompt.value;
  prompt.value = '';
  messageList.value = [
    ...messageList.value,
    {
      timestamp: new Date(),
    },
  ];
  nextTick(() => {
    const messageListContainer = document.getElementById('scrollableDiv');
    if (messageListContainer) {
      messageListContainer.scrollTop = messageListContainer.scrollHeight;
    }
  });
  try {
    const response = await chatbotStore.generateAndFormatOpenResponse(temp);
    messageList.value[messageList.value.length - 1] = {
      timestamp: new Date(),
      text: response,
    };
  } catch (error) {
    messageList.value[messageList.value.length - 1] = {
      timestamp: new Date(),
      text: { body: `There was an error retreiving your response: ${error}` },
    };
  }
  nextTick(() => {
    const messageListContainer = document.getElementById('scrollableDiv');
    if (messageListContainer) {
      messageListContainer.scrollTop = messageListContainer.scrollHeight;
    }
  });
};

const checkCharacterCount = () => {
  const characterLimit = 42000;
  let errorMes =
    prompt.value.length <= characterLimit
      ? null
      : `Prompt must be less than ${characterLimit} characters`;
  errorMessage.value = errorMes;
};
</script>

<template>
  <div class="flex flex-col justify-end">
    <label
      :for="id"
      class="p-0.5"
      :class="
        props.labelClasses ? props.labelClasses : 'text-gray-500 font-bold'
      "
    >
      {{ label }}
    </label>

    <textarea
      :id="id"
      class="h-[100px] border-2 rounded p-2"
      :class="{
        [props.css_classes]: true,
        'border-red-600': props.error,
      }"
      :value="props.modelValue"
      :maxlength="props.maxLength"
      cols="60"
      @input="$emit('update:modelValue', $event.target.value)"
    />
  </div>
</template>

<script setup>
const id = `text-area-${Date.now()}`;

const props = defineProps({
  label: {
    default: '',
    type: String,
  },
  labelClasses: {
    default: '',
    type: String,
  },
  modelValue: {
    default: '',
    type: String,
  },
  options: {
    // object/array defaults should be returned from a factory function
    default() {
      return [];
    },
    type: Array,
  },
  maxLength: {
    default: 255,
    type: Number,
  },
  css_classes: {
    default: '',
    type: String,
  },
  error: {
    default: false,
    type: Boolean,
  },
});
defineEmits(['update:modelValue']);
</script>

import { defineStore } from 'pinia';
import { API } from 'aws-amplify';
import getDynamicPath from '../utils/getDynamicPath';
import { getAuthenticatedHeaders } from '@/utils/auth';
import { chatAIConfig } from '@/utils/constants';

export const useChatbotStore = defineStore('chatbot', {
  state: () => ({
    notFound: false,
    responseLoading: false,
  }),
  getters: {
    //no getters yet
  },
  actions: {
    async generateAndFormatOpenResponse(prompt) {
      const headers = await getAuthenticatedHeaders();
      const payload = {
        body: {
          message: prompt,
        },
        ...headers,
      };
      const response = await API.post(
        'insurance',
        getDynamicPath('chat'),
        payload
      );
      //Eventually this stuff will be parsed out of the response
      const body = response.data.message;
      const randomNumImage = Math.random();
      const randomNumLink = Math.random();
      const imageUrl =
        randomNumImage >= 0.5
          ? 'https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png'
          : null;
      const linkText = 'Hardcoded Sample Link';
      const linkUrl =
        randomNumLink >= 0.5
          ? 'https://docs.aws.amazon.com/bedrock/latest/APIReference/API_agent-runtime_RetrieveAndGenerate.html'
          : null;

      const text = {
        body,
        imageUrl,
        linkText,
        linkUrl,
      };
      return text;
      // return response.data.message;
    },
    async generateAndFormatResponse(prompt, props) {
      const headers = await getAuthenticatedHeaders();

      const client = process.env['VUE_APP_CLIENT_PREFIX'] || 'default';
      const chat_config = chatAIConfig[client] || chatAIConfig['default'];

      const questionObject = chat_config[props.page].find(
        question => question.questionText === prompt
      );
      let url = questionObject.quickLinkUrl;

      const payload = {
        body: {
          message: prompt,
        },
        ...headers,
      };

      //prompt is dynamic url
      if (url.includes('<')) {
        url = url.replace(/<[^>]*>/, props.itemId);
      }
      const methodType = questionObject.quickLinkMethod;
      url = getDynamicPath(`chat${url}`);

      const response = await API[(methodType || 'POST').toLowerCase()](
        'insurance',
        url,
        payload
      );
      const body = response.data;
      const imageUrl =
        'https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png';
      const linkText = 'Hardcoded Sample Link';
      const linkUrl =
        'https://docs.aws.amazon.com/bedrock/latest/APIReference/API_agent-runtime_RetrieveAndGenerate.html';

      const text = {
        body,
        imageUrl,
        linkText,
        linkUrl,
      };
      return text;
    },
  },
});

<template>
  <a id="popoverElement" class="underline text-blue-500 text-xs cursor-pointer">
    {{ props.hoverText }}
  </a>
  <div
    data-popover
    id="popoverContent"
    role="tooltip"
    class="absolute z-10 invisible inline-block text-sm text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0 w-72"
  >
    <div class="p-3 space-y-2">
      <h3 class="text-sm font-bold">Disclaimer</h3>
      <p>
        This AI assistant is designed to provide guidance and recommendations to
        assist with decision-making. While it strives to provide accurate and
        helpful guidance, it is not perfect and may make mistakes. Users are
        responsible for ensuring that their actions comply with industry
        guidelines, regulations, and best practices. If you find an issue,
        please report it via in-product feedback or support.
      </p>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { Popover } from 'flowbite';

const props = defineProps({
  hoverText: {
    type: String,
    default: '',
  },
});

onMounted(() => {
  const $targetEl = document.getElementById('popoverContent');
  const $triggerEl = document.getElementById('popoverElement');

  if ($targetEl && $triggerEl) {
    const options = {
      placement: 'bottom',
      triggerType: 'hover',
      offset: 7,
    };

    const popover = new Popover($targetEl, $triggerEl, options);
  }
});
</script>

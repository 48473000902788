<template>
  <PageView :not-found="workItems_store.notFound">
    <UserAnalytics />
    <!-- WIP MODAL -->
    <ModalComponent
      :modal-active="showWipModal"
      :close="async () => await toggleWipModal(false)"
    >
      <div
        v-show="workItems_store.isLoading"
        class="flex items-center justify-center"
      >
        <LoadingSpinner />
      </div>
      <div v-show="!workItems_store.isLoading" class="text-xs">
        <h1 class="text-center text-2xl m-0.5 mb-3">
          Are you sure you want to add this to your WIP?
        </h1>
        <form
          id="markWipForm"
          class="mt-5 text-left text-xs"
          @submit.prevent="addToWip"
        >
          <DropDown
            v-model="wipReason"
            :required="true"
            label="Reason for Adding to WIP*"
            labelClass="text-primary text-rev-base font-semibold"
            listClass="text-rev-sm text-gray-700"
            placeholder="Select an Action"
            :options="workItems_store.activityLookup"
            :dropdown-class="badAttempt ? 'border-red-700' : ''"
          />
          <TextAreaInput
            v-model="wipNotes"
            label="Notes"
            labelClasses="text-rev-base text-primary font-semibold"
            css_classes="text-rev-sm text-gray-700"
            :max-length="1000"
          />
          <div
            v-show="errorMessage !== null"
            class="text-red-500 text-center border-solid border rounded p-0.5 mt-3 mx-1.5 border-red-500 bg-red-100"
          >
            {{ errorMessage }}
          </div>
          <div class="mt-5 flex justify-between">
            <OutlinedButton
              class="w-36 mr-2 text-rev-sm"
              type="button"
              @click="async () => await toggleWipModal(false)"
            >
              Cancel
            </OutlinedButton>
            <SolidButton
              class="w-36 text-rev-sm"
              :class="wipAllowSubmit === true ? '' : '!bg-slate-300'"
              :disabled="wipAllowSubmit === false"
              type="submit"
            >
              Add to WIP
            </SolidButton>
          </div>
        </form>
      </div>
    </ModalComponent>

    <!-- COMPLETE MODAL -->
    <ModalComponent
      :modal-active="showCompleteModal"
      :close="async () => await toggleCompleteModal(false)"
    >
      <div
        v-show="workItems_store.isLoading"
        class="flex items-center justify-center"
      >
        <LoadingSpinner />
      </div>
      <div v-show="!workItems_store.isLoading" class="text-xs">
        <h1 class="text-2xl m-0.5 mb-3 text-center">
          Great Job! You've completed a task!
        </h1>
        <form
          id="markCompleteForm"
          class="mt-5 text-left text-xs text-gray-400"
          @submit.prevent="markComplete"
        >
          <DropDown
            v-model="completeAction"
            :required="true"
            label="Action Completed*"
            placeholder="Select an Action"
            :options="workItems_store.activityLookup"
            :dropdown-class="
              !completeAction && badAttempt ? 'border-red-700' : ''
            "
            labelClass="text-primary text-rev-base font-semibold"
            listClass="text-rev-sm text-gray-700"
          />
          <TextAreaInput
            v-model="completeNotes"
            label="Notes*"
            labelClasses="text-rev-base text-primary font-semibold"
            css_classes="text-rev-sm text-gray-700"
            :max-length="1000"
            :error="!completeNotes && badAttempt"
          />
          <!-- <CheckBoxInput v-model="updatedEHR" label="I've updated the EHR" /> -->
          <div
            v-show="errorMessage !== null"
            class="text-red-500 text-center border-solid border rounded p-0.5 mt-3 mx-1.5 border-red-500 bg-red-100"
          >
            {{ errorMessage }}
          </div>
          <div class="mt-5 flex justify-between m-0.5">
            <OutlinedButton
              class="light w-36 mr-2 text-rev-sm"
              @click="async () => await toggleCompleteModal(false)"
            >
              Cancel
            </OutlinedButton>
            <SolidButton
              class="dark w-36 text-rev-sm"
              type="submit"
              :class="completeLoading === true ? '!bg-slate-300' : ''"
              :disabled="completeLoading"
            >
              Submit
            </SolidButton>
          </div>
        </form>
      </div>
    </ModalComponent>

    <!-- ADD NOTE MODAL  -->
    <ModalComponent :modal-active="showAddNoteModal" :close="closeAddNoteModal">
      <div
        class="flex flex-col justify-center items-center w-full h-full"
      ></div>
      <h1 class="text-rev-base text-center mb-4 text-primary font-semibold">
        Add Note
      </h1>
      <form @submit.prevent="saveNoteToWorkItem">
        <div class="text-rev-sm text-gray-400">
          <TextAreaInput
            v-model="workItemNote"
            :max-length="1000"
            labelClasses="text-rev-base text-primary font-semibold"
            css_classes="text-rev-sm text-gray-700"
          />
        </div>
        <div class="flex w-full justify-between mt-3">
          <OutlinedButton class="w-36 text-rev-sm" @click="closeAddNoteModal"
            >Cancel</OutlinedButton
          >
          <SolidButton
            class="w-36 text-rev-sm"
            :class="noteIsSaving === true ? '!bg-slate-300' : ''"
            :disabled="noteIsSaving"
            type="submit"
          >
            Save
          </SolidButton>
        </div>
      </form>
    </ModalComponent>
    <!-- ERROR MODAL  -->
    <ModalComponent :modal-active="showErrorModal" :close="dismissErrorModal">
      <div
        class="flex flex-col justify-center items-center w-full h-full"
      ></div>
      <h1 class="text-xl text-center mb-4 text-primary">
        Please notify your supervisor
      </h1>
      <div class="text-xs text-center mb-6 text-red-500">
        {{ errorMessage }}
      </div>
      <div class="flex w-full justify-center">
        <button class="w-36 light" @click="dismissErrorModal">Dismiss</button>
      </div>
    </ModalComponent>

    <!-- Work Item Details View -->
    <div class="flex gap-2 h-full">
      <div class="h-full grow relative">
        <div class="flex w-full h-fit mb-3">
          <!-- Back Arrow + User ID -->
          <div class="flex items-center mr-3">
            <div class="w-3 mr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="3"
                stroke="#265170"
                class="w-6 h-6 cursor-pointer"
                @click="navigateBack"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
                />
              </svg>
            </div>
            <p
              id="quickViewScrollTarget"
              class="text-rev-3xl text-primary font-bold mx-3"
              :style="{ width: 'max-content' }"
            >
              {{ workItems_store?.workItem?.patient_name }}
            </p>

            <div
              v-if="workItems_store?.workItem?.deactivated == true"
              class="w-3"
            >
              <img
                title="Deactivated"
                :src="YellowExclamationPoint"
                class="h-[32px]"
              />
            </div>

            <div
              v-else-if="workItems_store?.workItem?.is_priority == true"
              class="w-6"
            >
              <img title="Priority" :src="Priority" class="h-[32px]" />
            </div>
          </div>

          <!-- Complete + WIP Buttons + Workflow Status + Assignment -->
          <div class="flex w-full items-center text-rev-sm">
            <SolidButton
              class="dark w-28 mr-4 text-rev-sm"
              @click="async () => await toggleCompleteModal(true)"
            >
              Complete
            </SolidButton>
            <OutlinedButton
              v-if="
                getWorkflowStatus() === 'Assigned' ||
                getWorkflowStatus() === 'Ready to start'
              "
              class="light w-28 mr-4 text-rev-sm"
              @click="async () => await toggleWipModal(true)"
            >
              Add to WIP
            </OutlinedButton>

            <!-- Workflow Status -->
            <Popper
              :hover="true"
              :locked="true"
              placement="right"
              :disabled="getWorkflowStatus() !== 'Inactive'"
            >
              <div
                v-show="getWorkflowStatus()"
                class="mr-4 w-auto bg-yellow-one p-1 text-black rounded leading-3 text-rev-sm"
              >
                {{ getWorkflowStatus() }}
              </div>
              <template #content>
                <div
                  slot="reference"
                  class="bg-white p-2 rounded font-thin text-rev-sm shadow max-w-[15em]"
                >
                  This workitem was not ready to be processed this morning.
                </div>
              </template>
            </Popper>

            <!-- Assignment -->
            <div
              v-show="workItems_store?.workItem?.assignee_id"
              class="flex items-center mr-4"
            >
              <UserPicture
                :user="
                  users_store.getUserById(
                    workItems_store?.workItem?.assignee_id
                  )
                "
              />
            </div>
            <div class="text-primary text-base pt-1">
              <b>RSN:</b>
              {{ rsn || 'INVALID RSN' }}
            </div>
            <!-- More Actions Dropdown -->
            <div
              v-if="
                hasGenerateDocumentsPermission === true ||
                hasAddNotesPermission === true ||
                hasCheckClaimStatus === true
              "
              id="action-drop-down"
              class="relative float-right w-[150px] cursor-pointer scroll-my-3"
              style="margin-left: auto"
            >
              <div
                ref="showMoreActionsRef"
                class="flex justify-between items-center bg-white rounded-sm shadow-xl p-2 w-full"
                @click="toggleMoreActions"
              >
                <div class="text-primary text-rev-sm">More Actions</div>
                <div
                  :class="{
                    'rotate-180': showMoreActions,
                  }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2.5"
                    stroke="#265170"
                    class="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </div>
              </div>
              <Transition>
                <div
                  v-show="showMoreActions"
                  class="absolute right-0 top-9 shadow-xl z-50 w-full"
                >
                  <div class="float-right flex flex-col bg-white w-full">
                    <hr />
                    <button
                      v-if="hasAddNotesPermission === true"
                      class="p-2 text-primary text-rev-sm hover:bg-gray-300 rounded-none text-left"
                      @click="openAddNoteModal"
                    >
                      Add a Note
                    </button>
                    <hr />
                    <button
                      v-if="
                        hasCheckClaimStatus === true &&
                        workItemCheckClaimStatus()
                      "
                      class="p-2 text-primary text-rev-sm hover:bg-gray-300 rounded-none text-left"
                      @click="getClaimStatus"
                    >
                      Check Claim Status
                    </button>
                    <hr />
                    <button
                      v-if="hasGenerateDocumentsPermission === true"
                      :disabled="isGeneratingAppeal === true"
                      class="p-2 text-primary text-rev-sm hover:bg-gray-300 rounded-none text-left"
                      @click="createNewDocument()"
                    >
                      Generate Appeal
                    </button>
                  </div>
                </div>
              </Transition>
            </div>
          </div>
        </div>

        <div class="flex flex-col">
          <div id="work-item-container" class="flex w-full">
            <WorkItemDetails />
            <ActivityStream />
          </div>
          <div class="items-end">
            <SupportPopper />
          </div>
        </div>
      </div>
      <ChatAI
        v-if="featureFlagPass"
        :buttonList="buttonList"
        :page="'workItemDetails'"
        :itemId="rsn"
      />
    </div>
  </PageView>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { onClickOutside } from '@vueuse/core';
import YellowExclamationPoint from '@/assets/yellow_exclamation_point.svg';
import Priority from '@/assets/priority.svg';
import Popper from 'vue3-popper';
import { toast } from 'vue3-toastify';

import { useUsersStore } from '@/stores/useUsers';
import { useWorkItemsStore } from '@/stores/useWorkItems';
import { useDocumentsStore } from '@/stores/useDocuments';
import { useSearchStore } from '@/stores/useSearch';

import router from '@/router';
import ModalComponent from '@/components/ModalComponent.vue';
import DropDown from '@/components/forms/DropDown.vue';
import TextAreaInput from '@/components/forms/TextAreaInput.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import SolidButton from '@/components/buttons/SolidButton.vue';
import OutlinedButton from '@/components/buttons/OutlinedButton.vue';
import ChatAI from '@/components/ChatAI.vue';

import ActivityStream from './ActivityStream.vue';
import WorkItemDetails from './WorkItemDetails.vue';
import PageView from '../../components/PageVue.vue';

import { ACTIVITY_TYPES, toasts } from '@/utils/constants';
import UserPicture from '@/components/UserPicture.vue';
import UserAnalytics from '@/components/UserAnalytics.vue';

import { chatAIConfig } from '@/utils/constants';
import SupportPopper from '@/components/SupportPopper.vue';

/* stores */
const users_store = useUsersStore();
const route = useRoute();
const workItems_store = useWorkItemsStore();
const documents_store = useDocumentsStore();

/* local state */
const showMoreActions = ref(false);
const showMoreActionsRef = ref(null);
const rsn = ref('');
const featureFlagPass = ref(false);
const buttonList = ref([]);

/* wip modal state */
const showWipModal = ref(false);
const wipReason = ref(null);
const wipNotes = ref(null);
const wipAllowSubmit = ref(true);

/* complete modal state */
const showCompleteModal = ref(false);
const completeAction = ref(null);
const completeNotes = ref(null);
const updatedEHR = ref(false);
const badAttempt = ref(false);
const completeLoading = ref(false);

/* add note to workitem modal state */
const showAddNoteModal = ref(false);
const workItemNote = ref(null);
const noteIsSaving = ref(false);

/* error modal state */
const showErrorModal = ref(false);
const errorMessage = ref(null);

/* generate appeal state */
const isGeneratingAppeal = ref(false);

const search_store = useSearchStore();

// The search modal should only show when returning from the work item details page
onUnmounted(() => {
  search_store.setJustSearched(false);
  workItems_store.reset();
});

watch(completeAction, () => {
  badAttempt.value = completeAction.value == null && badAttempt.value;
});

watch(route, async to => {
  const workItemId = to.params?.id;
  if (workItemId) {
    await workItems_store.fetchWorkItem(workItemId);
    await workItems_store.fetchWorkItemActivities(workItemId);
    await workItems_store.fetchWorkItemCharges(workItemId);
    await workItems_store.fetchWorkItemTransactions(workItemId);
    await documents_store.fetchAssociatedDocuments(workItemId);
  } else {
    workItems_store.workItem = null;
  }
});

onMounted(async () => {
  const workItemId = route.params?.id;
  rsn.value = workItemId;

  if (workItemId) {
    await workItems_store.fetchWorkItem(workItemId);
    await workItems_store.fetchWorkItemActivities(workItemId);
    await workItems_store.fetchWorkItemCharges(workItemId);
    await workItems_store.fetchWorkItemTransactions(workItemId);
    await documents_store.fetchAssociatedDocuments(workItemId);
  }
  featureFlagPass.value = Boolean(process.env['VUE_APP_CHAT_AI_FF'] == 'true');
});

const hasAddNotesPermission = ref(false);
const hasGenerateDocumentsPermission = ref(false);
const hasCheckClaimStatus = ref(false);

onMounted(async () => {
  hasGenerateDocumentsPermission.value =
    (await users_store.hasPermission('GENERATE_DOCUMENTS')) === true;
  hasAddNotesPermission.value =
    (await users_store.hasPermission('ADD_NOTES')) === true;
  hasCheckClaimStatus.value =
    (await users_store.hasPermission('CHECK_CLAIM_STATUS')) === true;
});

onMounted(() => {
  const details =
    chatAIConfig[process.env['VUE_APP_CLIENT_PREFIX']] ||
    chatAIConfig['default'];
  buttonList.value = details.workItemDetails.map(item => item.questionText);
});

const openAddNoteModal = () => {
  showAddNoteModal.value = true;
  showMoreActions.value = false;
};

const closeAddNoteModal = e => {
  e?.preventDefault();
  showAddNoteModal.value = false;
  workItemNote.value = null;
};

const saveNoteToWorkItem = async () => {
  let errorMessage = !workItemNote.value
    ? 'Note must not be blank.'
    : 'Note must be under 1000 characters';
  if (!workItemNote.value) {
    toast.error(errorMessage, {
      autoClose: 5000,
    });
    return;
  }

  const workItemId = route.params.id;
  workItems_store.isLoading = true;
  noteIsSaving.value = true;
  try {
    await workItems_store.postActivity(
      workItemId,
      ACTIVITY_TYPES['NOTE'].id,
      ACTIVITY_TYPES['NOTE'].name,
      null,
      null,
      workItemNote.value,
      workItems_store.getWorkItemValue('ua_id')
    );

    await workItems_store.fetchWorkItemActivities(workItemId);

    showAddNoteModal.value = false;
    workItemNote.value = null;
  } catch (error) {
    console.error(error);
  } finally {
    workItems_store.isLoading = false;
    noteIsSaving.value = false;
  }
};

const WORKFLOW_STATUS_MAP = {
  Ready: 'Assigned',
  WIP: 'WIP',
  Complete: 'Complete',
  Inactive: 'Inactive',
  Unassigned: 'Unassigned',
};

const workFlowStatus = ref(null);

const workItemCheckClaimStatus = () => {
  return workItems_store.workItem?.can_get_claim_status;
};

const getWorkflowStatus = () => {
  let possibleStatus = workItems_store?.workItem?.workflow_status;
  if (
    workItems_store?.workItem?.deactivated == true &&
    possibleStatus != 'WIP'
  ) {
    workFlowStatus.value = 'Inactive';
  } else {
    const workflow_status_key = workItems_store?.workItem?.workflow_status;
    workFlowStatus.value = WORKFLOW_STATUS_MAP[workflow_status_key];
  }
  return workFlowStatus.value;
};

const navigateBack = () => {
  const nestedRoutes = ['/document'];

  if (window.history.state.back) {
    window.history.state.back.includes(nestedRoutes)
      ? router.push('/lead')
      : router.go(-1);
  } else {
    router.push('/lead');
  }

  if (search_store.justSearched && search_store.input) {
    search_store.setShowSearchModal(true);
    search_store.setJustSearched(false);
  }
};

const toggleMoreActions = () => {
  showMoreActions.value = !showMoreActions.value;
};

const toggleWipModal = async value => {
  if (!value) {
    wipReason.value = null;
    wipNotes.value = null;
    errorMessage.value = null;
  }

  if (value)
    await workItems_store.fetchActivitiesLookup(
      ACTIVITY_TYPES['ADDED_TO_WIP'].id
    );

  badAttempt.value = false;
  showWipModal.value = value;
};

const getClaimStatus = async () => {
  if (workItems_store.isClaimStatusLoading)
    toast.error('Claim status check in progress');
  else {
    workItems_store.isClaimStatusLoading = true;
    const workItemId = route.params.id;
    showMoreActions.value = false;
    const { message, success } = await workItems_store.getClaimStatus(
      workItemId
    );
    if (success) {
      toast.success(message);
    }
  }
};

const createNewDocument = async () => {
  if (isGeneratingAppeal.value === false) {
    isGeneratingAppeal.value = true;
    try {
      const workItemId = route.params.id;
      await workItems_store.fetchWorkItem(workItemId);
      const items = { ...workItems_store.workItem };

      const userName = `${users_store.activeUser.first_name} ${users_store.activeUser.last_name}`;

      await documents_store.createNewDocument(items, userName);
      if (documents_store.errorMessage) {
        toast.error(documents_store?.errorMessage || toasts.DEFAULT_ERROR, {
          autoClose: 5000,
        });
        return;
      }
      router.push({
        path: `/document/${workItemId}`,
      });
    } catch (err) {
      console.error(err);
    } finally {
      isGeneratingAppeal.value = false;
    }
  }
};

const toggleCompleteModal = async value => {
  if (!value) {
    completeAction.value = null;
    completeNotes.value = null;
    updatedEHR.value = false;
    errorMessage.value = null;
  }
  if (value)
    await workItems_store.fetchActivitiesLookup(ACTIVITY_TYPES['COMPLETED'].id);
  badAttempt.value = false;
  showCompleteModal.value = value;
};

const addToWip = async () => {
  if (!wipAllowSubmit.value) {
    return;
  }
  if (wipReason.value == null) {
    badAttempt.value = true;
  } else {
    wipAllowSubmit.value = false;
    const workItemId = route.params.id;

    await workItems_store
      .setWorkItemStatus(workItemId, 'wip')
      .then(async response => {
        const {
          data: { ua_id },
          message,
          success,
        } = response;

        if (success) {
          // Post work activity
          await workItems_store.postActivity(
            workItemId,
            ACTIVITY_TYPES['ADDED_TO_WIP'].id,
            ACTIVITY_TYPES['ADDED_TO_WIP'].name,
            wipReason.value,
            workItems_store.activityLookup.find(e => e.key == wipReason.value)
              ?.value,
            wipNotes.value,
            ua_id
          );

          navigateBack();
        } else {
          throw message;
        }
      })
      .catch(error => {
        console.error(error);
        toast.error(error || toasts.DEFAULT_ERROR, {
          autoClose: 5000,
        });
      })
      .finally(() => {
        toggleWipModal(false);
        wipAllowSubmit.value = true;
      });
  }
};

const markComplete = async () => {
  if (completeAction.value == null || completeNotes.value == null) {
    badAttempt.value = true;
  } else if (completeNotes?.value?.length === 0) {
    return;
  } else {
    completeLoading.value = true;
    const workItemId = route.params.id;
    const searchResultIDs = search_store.searchResults.map(
      result => result.revology_system_number
    );
    const fromSearch = searchResultIDs.indexOf(parseInt(workItemId)) != -1;

    await workItems_store
      .setWorkItemStatus(workItemId, 'complete', fromSearch)
      .then(async response => {
        const {
          data: { ua_id },
          message,
          success,
        } = response;

        if (success) {
          // Post work activity
          await workItems_store.postActivity(
            workItemId,
            ACTIVITY_TYPES['COMPLETED'].id,
            ACTIVITY_TYPES['COMPLETED'].name,
            completeAction.value,
            workItems_store.activityLookup.find(
              e => e.key == completeAction.value
            )?.value,
            completeNotes.value,
            ua_id
          );

          navigateBack();
        } else {
          throw message;
        }
      })
      .catch(error => {
        console.error(error);
        toast.error(error || toasts.DEFAULT_ERROR, {
          autoClose: 5000,
        });
      })
      .finally(() => {
        completeLoading.value = false;
        toggleCompleteModal(false);
      });
  }
};

const dismissErrorModal = () => {
  showErrorModal.value = false;
  errorMessage.value = null;
};

onClickOutside(
  showMoreActionsRef,
  () => {
    showMoreActions.value = false;
  },
  {
    ignore: [],
  }
);
</script>
<style scoped></style>

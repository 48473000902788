export const ACTIVITY_TYPES = {
  ADDED_TO_LEAD: {
    id: 1,
    name: 'Added to Lead',
  },
  ADDED_TO_WIP: {
    id: 2,
    name: 'Added to WIP',
  },
  COMPLETED: {
    id: 3,
    name: 'Completed',
  },
  NOTE: {
    id: 4,
    name: 'Note',
  },
  REMOVED_FROM_WIP: {
    id: 5,
    name: 'Removed From WIP',
  },
  CHECK_CLAIM_STATUS: {
    id: 6,
    name: 'Check Claim Status',
  },
};

// List of all possible roles:
// ['REP', 'QA', 'TL', 'MGR', 'EXEC', 'ADM', 'AUD', 'MSPB', 'MSPA'],

export const PERMISSIONED_WORKFLOWS = {
  VIEW_DOCUMENTS: ['REP', 'QA', 'TL', 'MGR', 'EXEC', 'ADM', 'AUD'],
  ADD_NOTES: ['REP', 'QA', 'TL', 'MGR', 'EXEC', 'ADM', 'MSPB', 'MSPA'],
  VIEW_USER_PRODUCTIVITY: ['TL', 'MGR', 'EXEC', 'ADM', 'AUD'],
  REQUEST_WORK: [
    'REP',
    'QA',
    'TL',
    'MGR',
    'EXEC',
    'ADM',
    'MSPB',
    'MSPA',
    'AUD',
  ],
  GENERATE_DOCUMENTS: ['REP', 'QA', 'TL', 'MGR', 'ADM'],
  CHECK_CLAIM_STATUS: [
    'REP',
    'QA',
    'TL',
    'MGR',
    'EXEC',
    'ADM',
    'AUD',
    'MSPB',
    'MSPA',
  ],
  EDIT_AND_DELETE_WORKLISTS_AND_RULES: ['MGR', 'EXEC', 'ADM'],
  VIEW_USERS: ['EXEC', 'ADM', 'MGR'],
  VIEW_ADMIN: ['ADM'],
  VIEW_STATS: ['REP', 'QA', 'TL', 'MGR', 'EXEC', 'ADM', 'MSPB', 'MSPA', 'AUD'],
  VIEW_GAME_PLAN_TAB: ['QA', 'TL', 'MGR', 'EXEC', 'ADM', 'AUD', 'MSPB', 'MSPA'],
  VIEW_RULES_TAB: ['QA', 'TL', 'MGR', 'EXEC', 'ADM', 'AUD', 'MSPB', 'MSPA'],
};

/*
 * An enumeration to map user role abbreviations to user role titles.
 */
export const USER_ROLE_TITLES = {
  REP: 'Contributor',
  QA: 'Supporter',
  TL: 'Supporter',
  MGR: 'Leader',
  EXEC: 'Exec',
  ADM: 'Admin',
  AUD: 'Auditor',
  MSPB: 'MSP Basic',
  MSPA: 'MSP Advanced',
};

// SKUs
export const INSURANCE_BASE_SKU = '20101';
export const RCM_DASHBOARDS_BASE_SKU = '10101';
export const RCM_DASHBOARDS_ENHANCED_SKU = '10102';
export const WORKFORCE_MANAGEMENT_DASHBOARDS_BASE_SKU = '10201';
export const WORKFORCE_MANAGEMENT_DASHBOARDS_ENHANCED_SKU = '10202';
export const STATS_SKUS = [
  RCM_DASHBOARDS_BASE_SKU,
  RCM_DASHBOARDS_ENHANCED_SKU,
  WORKFORCE_MANAGEMENT_DASHBOARDS_BASE_SKU,
  WORKFORCE_MANAGEMENT_DASHBOARDS_ENHANCED_SKU,
];

export const toasts = {
  DEFAULT_SUCCESS: 'Success!',
  DEFAULT_ERROR: 'Error!',
  RULES_ERROR: 'Error fetching rules!',
  PRIORITY_RULES_ERROR: 'Error fetching priority rules!',
  TIMEOUT:
    'Your request seems to be taking longer than anticipated. Please check back in a few minutes.',
};

export const numericHeaders = ['currency', 'count'];
export const centeredHeaders = ['rank', 'dropdown', 'users', 'assignees'];

export const chatAIConfig = {
  mercy: {
    workItemDetails: [
      {
        questionText: 'How do I resolve this claim?',
        quickLinkUrl: '/workItem/<work_item_id>',
      },
      {
        questionText: 'How do I get the status of this claim?',
        quickLinkUrl: '/workItem/<work_item_id>',
      },
      {
        questionText: 'Another test question?',
        quickLinkUrl: '/otherTest',
      },
    ],
  },
  hancock: {
    workItemDetails: [
      {
        questionText: 'How do I resolve this claim?',
        quickLinkUrl: '/workItem/<work_item_id>',
      },
      {
        questionText: 'How do I get the status of this claim?',
        quickLinkUrl: '/workItem/<work_item_id>',
      },
      {
        questionText: 'Another test question?',
        quickLinkUrl: '/otherTest',
      },
    ],
  },
  rrh: {
    workItemDetails: [
      {
        questionText: 'How do I resolve this claim?',
        quickLinkUrl: '/workItem/<work_item_id>',
      },
      {
        questionText: 'How do I get the status of this claim?',
        quickLinkUrl: '/workItem/<work_item_id>',
      },
      {
        questionText: 'Another test question?',
        quickLinkUrl: '/otherTest',
      },
    ],
  },
  dev: {
    workItemDetails: [
      {
        questionText: 'How do I resolve this claim?',
        quickLinkUrl: '/workItem/<work_item_id>?question=resolve',
      },
      {
        questionText: 'Please generate me an appeal letter for this work item.',
        quickLinkUrl: '/workItem/<work_item_id>?question=generateLetter',
      },
      {
        questionText: 'Another test question?',
        quickLinkUrl: '/otherTest',
        quickLinkMethod: 'GET',
      },
    ],
    gamePlan: [
      {
        questionText: 'How do I evaluate this work strategy?',
        quickLinkUrl: '/workItem/<work_strategy_id>?question=evaluate',
      },
    ],
  },
  default: {
    workItemDetails: [
      {
        questionText: 'How do I resolve this claim?',
        quickLinkUrl: '/workItem/<work_item_id>?question=resolve',
      },
      {
        questionText: 'Please generate me an appeal letter for this work item.',
        quickLinkUrl: '/workItem/<work_item_id>?question=generateLetter',
      },
      {
        questionText: 'Another test question?',
        quickLinkUrl: '/otherTest',
        quickLinkMethod: 'GET',
      },
    ],
    gamePlan: [
      {
        questionText: 'How do I evaluate this work strategy?',
        quickLinkUrl: '/workItem/<work_strategy_id>?question=evaluate',
      },
    ],
  },
};
